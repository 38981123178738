<template>
	<div class="inquiry-card">
		<h4>비밀번호 재설정을 위한 이메일이 발송되었습니다.</h4>
		<p>
			입력해주신 이메일을 통해 발송된 링크로 접속하여.
			<br />비밀번호를 재설정 해주시길 바랍니다.
		</p>
		<div class="button-group">
			<router-link to="/"
				><button class="btn--md btn--fill-accent" href="/">
					홈으로
				</button></router-link
			>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
